import React from 'react';
import { useDispatch } from 'react-redux';
import { setPrivacy } from '../../../store/chat/chat-slice';
import { AppDispatch } from '../../../store/store';
import { Link } from 'react-router-dom';
import styles from './Privacy.module.scss'

const Privacy: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const handlePrivacy = () => {
    dispatch(setPrivacy(false));
  };

  return (
    <div className={styles.privacyContainer}>
      <div className={styles.backAbs} onClick={handlePrivacy}>
        Назад
      </div>

      <div className={styles.privacyContent}>
        <h1 className={styles.h1}>Соглашение об использовании аватара «Артём»</h1>
        <p className={`${styles.bold} ${styles.p}`}>
          Пожалуйста, прочтите текст ниже и кликните на клавишу «Да», чтобы подтвердить, что вы прочли и приняли правила
          ниже, заключив настоящее соглашение об использовании программно-информационного продукта аватара «Артем»,
          размещенного на следующем интернет-ресурсе: https://gipotireoz.ru/ (далее – Сайт).
        </p>
        <p className={styles.p}>
          Настоящий программно-информационный продукт аватар «Артем» (далее – Аватар) разработан ООО «Мерк» (далее –
          Разработчик) на основании массивов опубликованных данных по терапии соответствующих заболеваний; клинических
          рекомендаций; иных официальных документов и/или опубликованных источников.
        </p>
        <p className={styles.p}>
          Аватар разработан и функционирует на основе технологии искусственного интеллекта (ИИ).
        </p>
        <p className={styles.p}>
          Информация, полученная с помощью Аватара, приведена исключительно в справочно-информационных целях.
        </p>
        <p className={styles.p}>
          Настоящий Аватар разработан <span className={styles.bold}>ДЛЯ ИСПОЛЬЗОВАНИЯ МЕДИЦИНСКИМИ РАБОТНИКАМИ в целях получения ими изложенной в визуально доступной форме информации о заболеваниях щитовидной железы, а также в целях ознакомления медицинских работников с информацией в области эндокринологии.</span> Аватар
          является способом представления информации из массивов опубликованных данных по терапии соответствующих
          заболеваний; клинических рекомендаций; иных официальных документов и/или опубликованных источников.<span
          className={styles.bold}>Аватар НЕ ЯВЛЯЕТСЯ МЕДИЦИНСКИМ ИЗДЕЛИЕМ И НЕ ПРЕДНАЗНАЧЕН ДЛЯ ПОСТАНОВКИ ДИАГНОЗОВ, НАЗНАЧЕНИЯ ЛЕЧЕНИЯ КОНКРЕТНЫМ ПАЦИЕНТАМ, ОПРЕДЕЛЕНИЯ ПОРЯДКА ЗАПОЛНЕНИЯ РЕЦЕПТУРНЫХ БЛАНКОВ.</span>Настоящий
          Аватар <span
          className={styles.bold}>НЕ</span> предназначен для оказания медицинской помощи либо использования при оказании
          медицинской помощи пациентам, в том числе в клинической и лабораторной диагностике, для профилактики и лечения
          заболеваний.</p>
        <p className={styles.p}>Пользователями Аватара могут быть <span className={styles.bold}>ТОЛЬКО</span> физические
          лица, имеющие медицинское образование, работающие в медицинской организации, осуществляющей медицинскую
          деятельность на основании действующей лицензии на территории РФ</p>
        <p className={styles.p}>
          <span className={styles.bold}>ОТВЕТСТВЕННОСТЬ ЗА ЛЮБОЕ ИСПОЛЬЗОВАНИЕ РЕЗУЛЬТАТОВ, полученных при работе с Аватаром НЕСЕТЕ ВЫ КАК ПОЛЬЗОВАТЕЛЬ </span>
          Аватара. Разработчик Аватара, а равно иное лицо, при поддержке которого мог быть создан Аватар, не несут перед
          пользователями и иными третьими лицами ответственности (предел ответственности составляет «0» (ноль) рублей)
          за работу Аватара и использование информации, полученной с его помощью, в т.ч. за корректность, релевантность
          и применимость такой полученной информации в каких-либо конкретных ситуациях, возникающих в клинической
          практике.
        </p>
        <h1 className={styles.p}>Право использования Аватара <span className={styles.bold}>предоставляется на безвозмездной основе.</span>
        </h1>

        <div className={styles.agreementText}>
          <h3 className={styles.h3}>Принимая настоящие Правила, ВЫ (Пользователь) ЗАВЕРЯЕТЕ, что Вы:</h3>
          <ol className={`${styles.agreementList} ${styles.ol}`}>
            <li className={styles.li}>являетесь медицинским работником;</li>
            <li className={styles.li}>понимаете, что аватар разработан исключительно в справочно-информационных целях
              и
              предоставляется в пользование на территории РФ на условиях «как есть» (в т.ч. без каких-либо
              обязательств
              со стороны ООО «Мерк» по верификации конкретных полученных с использованием аватара результатов);
            </li>
            <li className={styles.li}>планируете использовать аватар исключительно в тех целях, для которых он
              разработан, и указанных выше в настоящем соглашении;
            </li>
            <li className={styles.li}>понимаете, что ответственность за любое использование результатов, полученных
              при
              использовании Вами аватара, лежит на Вас;
            </li>
            <li className={styles.li}>понимаете, что <span className={styles.bold}>РАЗРАБОТЧИК НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ПОСЛЕДСТВИЯ ЕГО ИСПОЛЬЗОВАНИЯ ВАМИ, а равно за последствия использование любой информации, полученной с использованием Аватара.</span>Разработчик
              не гарантирует корректной и бесперебойной работы Аватара (включая доступность ссылок, размещенных в
              Аватаре), актуальности информации на день обращения к Аватару, совместимости Аватара с установленным на
              Вашем персональном компьютере программным обеспечением (в т.ч. браузерами), а равно соответствия иным
              Вашим ожиданиям от использования Аватара;
            </li>
            <li className={styles.li}>обязуетесь не использовать аватар для осуществления какой-либо запрещенной
              законом
              деятельности;
            </li>
            <li className={styles.li}>обязуетесь не загружать в Аватар какие-либо данные, которые могут быть
              квалифицированы как персональные данные и/или сведения, составляющие медицинскую или иную охраняемую
              законом информацию;
            </li>
            <li className={styles.li}>понимаете, что ни Аватар, ни Сайт не сохраняет и/или каким-либо иным образом не
              аккумулирует введенную информацию, а все результаты выдачи производятся одномоментно в режиме реального
              времени;
            </li>
            <li className={styles.li}>обязуетесь не предпринимать каких-либо действий, направленных на нарушение работы
              Аватара, причинение ущерба программному обеспечению и Сайту, а равно не предпринимать иных противоправных
              действий в отношении Аватара и Сайта;
            </li>
            <li className={styles.li}>понимаете, что Разработчик вправе по своему усмотрению ограничивать или иным
              образом изменять используемый Аватаром массив данных;
            </li>
            <li className={styles.li}>обязуетесь сообщать администратору Сайта artem.katkov@merckgroup.com о выявленных
              случаях некорректной работы Аватара;
            </li>
          </ol>
        </div>
        <p className={styles.p}>
          Разработчик вправе по своему усмотрению вносить изменения в настоящие правила. Каждый пользователь имеет
          возможность ознакомиться с новой редакцией правил. Никакого дополнительного уведомления со стороны
          Разработчика для ознакомления пользователей с новой редакцией правил не требуется.
        </p>
        <p className={styles.p}>
          Настоящие соглашение и правила регулируются и толкуются в соответствии с законодательством Российской
          Федерации. В случае возникновения конфликтов при использовании Аватара спор передается на рассмотрение в
          компетентный суд по месту нахождения Разработчика.
        </p>
        <p className={styles.p}>
          <span className={styles.bold}>ОСУЩЕСТВЛЯЯ НАЖАТИЕ НА КЛАВИШУ «ДА», ВЫ ПОДТВЕРЖДАЕТЕ, ЧТО ПОЛНОСТЬЮ ОЗНАКОМИЛИСЬ С НАСТОЯЩИМИ ПРАВИЛАМИ, ВЫ ПОНИМАЕТЕ И ПОЛНОСТЬЮ ПРИНИМАЕТЕ ИХ СОДЕРЖАНИЕ, ПОДТВЕРЖДАЕТЕ УКАЗАННЫЕ ВЫШЕ ЗАВЕРЕНИЯ, А ТАКЖЕ В ДАЛЬНЕЙШЕМ ОБЯЗУЕТЕСЬ СОБЛЮДАТЬ ДАННЫЕ ПРАВИЛА.</span>
        </p>
        <button className={styles.back} onClick={handlePrivacy}>
          Назад
        </button>
      </div>
    </div>
  );
};

export default Privacy;
